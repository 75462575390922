.positionRelative {
  position: relative;
}
.listItemAlphabets {
  list-style-type: none;
  counter-reset: list-counter;
  padding-left: 20px;
  padding: 0px 0px 0px 20px;
}
.listItemAlphabets li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;
}
.listItemAlphabets li:before {
  content: '(' counter(list-counter, lower-alpha) ') ';
  counter-increment: list-counter;
  position: absolute;
  left: 0;
}
.displayFlexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.outsideBubble {
  margin: 0px;
}
.image-overlap {
  position: absolute;
  opacity: 0.4;
  mix-blend-mode: multiply;
}

.rightSideBubble {
  position: fixed;
  left: 9%;
  z-index: 100;
  top: 100px;
}
.rightSideAnimatedBubble {
  position: absolute;
  left: 9%;
  z-index: 100;
  top: 100px;
  animation: rightBubble 3s;
  animation-duration: 8s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
}
@keyframes rightBubble {
  0% {
    top: 100px;
  }
  25% {
    top: 120px;
  }
  75% {
    top: 140px;
  }
  100% {
    top: 100px;
  }
}
.topSideBubble {
  position: fixed;
  right: 0px;
  z-index: 100;
  top: 120px;
}
.topSideAnimatedBubble {
  position: absolute;
  right: 0px;
  z-index: 100;
  top: 120px;
  animation: topBubble 3s;
  animation-duration: 8s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
}
@keyframes topBubble {
  0% {
    top: 120px;
  }
  25% {
    top: 100px;
  }
  75% {
    top: 80px;
  }
  100% {
    top: 120px;
  }
}
.bottomSideBubble {
  position: fixed;
  left: 22%;
  z-index: 100;
  bottom: 20px;
}
.bottomSideAnimatedBubble {
  position: absolute;
  left: 22%;
  z-index: 100;
  bottom: 20px;
  animation: bottomBubble 3s;
  animation-duration: 8s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
}
@keyframes bottomBubble {
  0% {
    bottom: 20px;
  }
  25% {
    bottom: 10px;
  }
  75% {
    bottom: 5px;
  }
  100% {
    bottom: 20px;
  }
}
.bubblesContainer {
  position: absolute;
  height: 90%;
  width: 100%;
  top: 10%;
  /* border: 1px solid;
  */
}
.slider1_bubble1 {
  width: 12% !important;
  position: absolute;
  top: 0;
  right: 50px;
}
.slider1_animated_bubble1 {
  width: 12% !important;
  position: absolute;
  top: 0;
  right: 50px;
  animation: bubble 7s;
  animation-duration: 8s;
  animation-delay: 7s;
  animation-iteration-count: infinite;
}
@keyframes bubble {
  0% {
    top: 0px;
  }
  25% {
    top: -40px;
  }
  75% {
    top: -10px;
  }
  100% {
    top: 0px;
  }
}
.slider1_bubble2 {
  width: 12% !important;
  position: absolute;
  top: 60%;
  left: 40%;
}
.slider1_animated_bubble2 {
  width: 12% !important;
  position: absolute;
  top: 60%;
  left: 40%;
  animation: bubble2 7s;
  animation-duration: 8s;
  animation-delay: 7s;
  animation-iteration-count: infinite;
}
@keyframes bubble2 {
  0% {
    top: 60%;
  }
  25% {
    top: 65%;
  }
  75% {
    top: 62%;
  }
  100% {
    top: 60%;
  }
}
.slider1_bubble3 {
  position: absolute;
  width: 12% !important;
  bottom: 30px;
  right: 0px;
}
.slider1_animated_bubble3 {
  width: 12% !important;
  position: absolute;
  bottom: 30px;
  right: 0px;
  animation: bubble3 7s;
  animation-duration: 8s;
  animation-delay: 7s;
  animation-iteration-count: infinite;
}
@keyframes bubble3 {
  0% {
    bottom: 30px;
  }
  25% {
    bottom: 50px;
  }
  75% {
    bottom: 70px;
  }
  100% {
    bottom: 30px;
  }
}
.inner button {
  transition: all 2s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 1;
}

.animatedTitle {
  animation: movingBottomToTop ease infinite;
  -webkit-animation: movingBottomToTop ease infinite;
  animation-duration: 6s;
}

.animatedSubTitle {
  animation: movingBottomToTop ease infinite;
  -webkit-animation: movingBottomToTop ease infinite;
  animation-duration: 6s;
}

.fadeInAnimation {
  animation: fade 6s;
  -webkit-animation: fade 6s;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes movingBottomToTop {
  0% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
  }
}

.homeMain {
  position: absolute;
  border-radius: 15px;
  background: linear-gradient(180deg, #ffffff 14.48%, #d6e3eb 54.41%);
  width: 100%;
  height: 100%;
}

.homeCarouselMain {
  position: relative;
  z-index: 1;
  height: 700px;
  margin: -56px 0px 0px 0px;
}
.owl-carousel {
  height: 100%;
}
.owl-carousel .owl-stage {
  height: 100%;
}
.owl-carousel .owl-stage-outer {
  height: 100%;
}
.owl-carousel .owl-item {
  height: 100%;
}

.slider-wrapper {
  position: relative;
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0px;
  right: 0;
  overflow: hidden;
  object-fit: cover;
}

.slider-content {
  text-align: center;
  height: 100vh;
}
.slider-content .inner {
  padding: 15% 0 0 0px;
  box-sizing: border-box;
  position: relative;
  height: 70vh;
  top: 20%;
  text-align: left;
}
.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.slider-content .inner h1 {
  font-weight: 600;
  margin: 0 auto;
  color: #ffffff;
  font-size: 40px;
  line-height: 1;
}
.slider-content .inner p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 20px auto 30px;
}
.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.slider-content section span {
  color: #ffffff;
}
.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}
.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}
.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

.slider-wrapper .owl-dots {
  display: flex;
  position: relative;
  bottom: 14%;
  margin: 0px auto;
  width: 1200px;
  max-width: 100%;
}

.slider-wrapper .owl-dots .owl-dot.active {
  background: #1c1b1b;
  width: 55px;
}
.slider-wrapper .owl-dots .owl-dot {
  border: none;
  padding: 2px !important;
  margin: 3px;
  background: #d9d9d9;
  border-radius: 28px;
  width: 23px;
  height: 3px;
}

.homeRightBanner {
  height: 42%;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
}
.loginSection {
  position: static;
  margin-top: 5%;
}

@media only screen and (min-width: 1921px) and (max-width: 4320px) {
  .homeCarouselMain {
    height: 1000px;
  }
  .rightSideAnimatedBubble {
    left: 36.5%;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {
  .slider-content {
    height: '850px';
  }
  .homeCarouselMain {
    height: 780px;
  }
  .rightSideAnimatedBubble {
    left: 19%;
  }
}

@media (max-width: 768px) {
  .header {
    background: #fff;
  }
  .outsideBubble {
    display: none;
  }
  .loginSection {
    margin-top: 15%;
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slider-content {
    height: calc(80vh - 75px);
  }
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
